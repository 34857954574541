import React from 'react';

import SEO from '../../../blocks/SEO';
import Layout from '../../../blocks/Layout';
import BlueprintHeader from './BlueprintHeader';
import BlueprintDescription from './BlueprintDescription';
import BlueprintEmbed from './BlueprintEmbed';
import BlueprintQuestions from './BlueprintQuestions';

const Blueprint = ({ pathContext }) => {
  const { blueprint } = pathContext;

  return (
    <Layout>
      <SEO
        title={`Blueprints - ${blueprint.title} | Deadline Funnel`}
        description={blueprint.description}
      />
      <BlueprintHeader blueprint={blueprint} />
      <BlueprintDescription blueprint={blueprint} />
      <BlueprintEmbed blueprint={blueprint} />
      <BlueprintQuestions blueprint={blueprint} />
    </Layout>
  )
};

export default Blueprint;