import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlueprintQuestions.module.css';

import Text from '../../../../elements/Text';
import Container from '../../../../blocks/Container';
import Image from '../../../../elements/Image';

const BlueprintQuestions = ({ blueprint }) => {
  return (
    <div className={styles.blueprintQuestions}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className={`col-xl-6 col-lg-6 col-12 d-flex flex-column ${blueprint.questions.length % 2 === 0 ? 'justify-content-between' : ''}`}>
              {
                blueprint.questions.filter((_, index) => (index + 1) <= Math.ceil(blueprint.questions.length / 2)).map((item, index) => (
                  <div key={index} className={styles.blueprintQuestions__question}>
                    <div className="mb-3">{item.question}</div>
                    <Text>{item.answer}</Text>
                  </div>
                ))
              }
            </div>
            <div className={`col-xl-6 col-lg-6 col-12 d-flex flex-column ${blueprint.questions.length % 2 === 0 ? 'justify-content-between' : ''}`}>
              {
                blueprint.questions.filter((_, index) => (index + 1) > Math.ceil(blueprint.questions.length / 2)).map((item, index) => (
                  <div key={index} className={styles.blueprintQuestions__question}>
                    <div className="mb-3">{item.question}</div>
                    <Text>{item.answer}</Text>
                  </div>
                ))
              }
            </div>
          </div>
          <Image name="questions_bolder" className={styles.blueprintQuestions__bolder} />
          <Image name="questions_girl" className={styles.blueprintQuestions__girl} />
        </Container>
      </Container>
    </div>
  )
};

BlueprintQuestions.propTypes = {
  blueprint: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageBig: PropTypes.string.isRequired,
    adminImage: PropTypes.string.isRequired,
    detailedTitle: PropTypes.string.isRequired,
    detailedDescription: PropTypes.arrayOf(PropTypes.string).isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })).isRequired
  }).isRequired
};

export default BlueprintQuestions;