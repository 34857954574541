import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlueprintDescription.module.css';

import Container from '../../../../blocks/Container';
import Image from '../../../../elements/Image';
import Text from '../../../../elements/Text';
import Button from '../../../../elements/Button';
import FullWidthRow from '../../../../blocks/FullWidthRow';
import ResourceLink from './ResourceLink';

import { capitalize } from '../../../../../utils';

const BlueprintDescription = ({ blueprint }) => {
  const imageClassArray = blueprint.adminImage.split('_').map(name => capitalize(name));
  imageClassArray.splice(-1, 1);

  return (
    <div className={styles.blueprintDescription}>
      <Container type="big">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-center">
            <Image
              name={blueprint.adminImage}
              className={`${styles.blueprintDescription__image} ${styles[`blueprintDescription__image${imageClassArray.join('')}`]}`}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center">
            <Container type="mobile">
              <div className={styles.blueprintDescription__textContainer}>
                <div className={`${styles.blueprintDescription__title} mb-3`}>
                  {blueprint.detailedTitle}
                </div>
                {
                  blueprint.detailedDescription.map(text => (
                    <Text key={text} className="mb-4">{text}</Text>
                  ))
                }
                <Button
                  type="action"
                  className="mt-3 mb-xl-0 mb-lg-0 mb-md-0 mb-3"
                  href="https://start.deadlinefunnel.com/create-monthly"
                >
                  Start using this blueprint
                </Button>
              </div>
            </Container>
          </div>
        </div>
        <Container type="mobile">
          <FullWidthRow>
            <div className={styles.blueprintDescription__separator} />
          </FullWidthRow>
          <div className="row">
            <div className="col-12 d-flex flex-wrap justify-content-center">
              <ResourceLink
                className={styles.blueprintDescription__resourceLink}
                href="https://university.deadlinefunnel.com/"
                type="university"
              >
                Deadline Funnel University
              </ResourceLink>
              <ResourceLink
                className={styles.blueprintDescription__resourceLink}
                href="/case-studies"
                type="case"
              >
                Case Studies
              </ResourceLink>
              <ResourceLink
                className={styles.blueprintDescription__resourceLink}
                href="https://blog.deadlinefunnel.com/?_ga=2.74149586.1507366345.1567692903-1935589168.1559059170"
                type="blog"
              >
                Deadline Funnel Blog
              </ResourceLink>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )
};

BlueprintDescription.propTypes = {
  blueprint: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageBig: PropTypes.string.isRequired,
    adminImage: PropTypes.string.isRequired,
    detailedTitle: PropTypes.string.isRequired,
    detailedDescription: PropTypes.arrayOf(PropTypes.string).isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })).isRequired
  }).isRequired
};

export default BlueprintDescription;