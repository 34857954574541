import React from 'react';
import PropTypes from 'prop-types'

import styles from './ResourceLink.module.css';

import { capitalize } from '../../../../../../utils';

const ResourceLink = ({ href, className, children, type }) => {
  return (
    <a
      href={href}
      className={`${styles.resourceLink} ${styles[`resourceLink${capitalize(type)}`]} ${className}`}
    >
      <div className={styles.resourceLink__iconContainer}>
        <div className={styles.resourceLink__icon} />
      </div>
      <div className={styles.resourceLink__textContainer}>
        {children}
      </div>
    </a>
  )
};

ResourceLink.defaultProps = {
  className: ''
};

ResourceLink.propTypes = {
  type: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ResourceLink;