import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlueprintEmbed.module.css';

import Container from '../../../../blocks/Container';

const BlueprintEmbed = ({ blueprint }) => {
  return (
    <div className={styles.blueprintEmbed}>
      <Container type="big">
        <div className="row justify-content-center">
          <div className="col col-10 d-flex">
            <iframe title="blueprintembed" width="1200" height="800" src={blueprint.embedUrl} frameBorder="0" scrolling="no" allowFullScreen />
          </div>
        </div>
      </Container>
    </div>
  )
};

BlueprintEmbed.propTypes = {
  blueprint: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageBig: PropTypes.string.isRequired,
    adminImage: PropTypes.string.isRequired,
    detailedTitle: PropTypes.string.isRequired,
    embedUrl: PropTypes.string.isRequired,
    detailedDescription: PropTypes.arrayOf(PropTypes.string).isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })).isRequired
  }).isRequired
};

export default BlueprintEmbed;