import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlueprintHeader.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';

import { capitalize } from '../../../../../utils';

const BlueprintHeader = ({ blueprint }) => {
  return (
    <div className={`${styles.blueprintHeader} ${styles[`blueprintHeader${blueprint.name.split('-').map(name => capitalize(name)).join('')}`]}`}>
      <Container>
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-start h-100">
              <div className={styles.breadcrumbs}>
                <a href="/blueprints">Blueprints</a> / {blueprint.title}
              </div>
              {
                blueprint.featured &&
                <div className={styles.blueprintHeader__featuredMarker}>
                  <Image name="featured_star" className={styles.blueprintHeader__featuredMarkerImg} />
                  Featured
                </div>
              }
              <Title className={`${styles.blueprintHeader__title} mb-3 text-left`}>
                {blueprint.title}
              </Title>
              <Text className="text-left">{blueprint.description}</Text>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center p-0">
              <Image name={blueprint.imageBig} className={styles.blueprintHeader__image} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )
};

BlueprintHeader.propTypes = {
  blueprint: PropTypes.shape({
    name: PropTypes.string.isRequired,
    featured: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageBig: PropTypes.string.isRequired,
    adminImage: PropTypes.string.isRequired,
    detailedTitle: PropTypes.string.isRequired,
    detailedDescription: PropTypes.arrayOf(PropTypes.string).isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })).isRequired
  }).isRequired
};

export default BlueprintHeader;